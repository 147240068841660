import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: this.getColorObject(this.props.color || "#f17013"), // default color if none provided
      position: { top: 0, left: 0 },
    };
    this.swatchRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.color !== prevProps.color) {
      this.setState({ color: this.getColorObject(this.props.color) });
    }
  }

  getColorObject = (color) => {
    if (typeof color === "string" && color.startsWith("#")) {
      return this.hexToRgb(color);
    } else if (typeof color === "string" && color.startsWith("rgb")) {
      return this.rgbStringToObject(color);
    } else if (typeof color === "object") {
      return color;
    }
    return { r: 241, g: 112, b: 19, a: 1 }; // default color
  };

  hexToRgb = (hex) => {
    let r = 0,
      g = 0,
      b = 0,
      a = 1;
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return { r, g, b, a };
  };

  rgbStringToObject = (rgbString) => {
    const result = rgbString.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)/
    );
    if (result) {
      const r = parseInt(result[1], 10);
      const g = parseInt(result[2], 10);
      const b = parseInt(result[3], 10);
      const a = result[4] ? parseFloat(result[4]) : 1;
      return { r, g, b, a };
    }
    return { r: 241, g: 112, b: 19, a: 1 }; // default color
  };

  handleClick = () => {
    const rect = this.swatchRef.current.getBoundingClientRect();
    this.setState({
      displayColorPicker: !this.state.displayColorPicker,
      position: { top: rect.bottom, left: rect.left },
    });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
    this.props.onChange(color.rgb);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "36px",
          height: "14px",
          borderRadius: "2px",
          background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
        },
        swatch: {
          padding: "5px",
          background: "#fff",
          borderRadius: "1px",
          boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
          top: `${this.state.position.top}px`,
          left: `${this.state.position.left}px`,
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
        },
      },
    });

    return (
      <div>
        <div
          style={styles.swatch}
          onClick={this.handleClick}
          ref={this.swatchRef}
        >
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
