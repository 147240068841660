import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import { useAmazonContext } from "../../Contexts/AmazonContext";

// Function to fetch predefined templates from backend

const TemplateSelector = ({ onSelect }) => {
  let { predefinedQuizTemplates, setPredefinedQuizTemplates } =
    useAmazonContext();

  useEffect(() => {
    // const fetchTemplates = async () => {
    //   try {
    //     const response = await api.get("/fetchTemplates");
    //     setPredefinedTemplates(response.data);
    //     console.log("Fetched Templates:", response.data);
    //   } catch (error) {
    //     console.error("Error fetching templates:", error);
    //   }
    // };

    // fetchTemplates();

    setPredefinedQuizTemplates([
      {
        name: "Multi choice - one answer",
        question: "What is the fundamental reason Allah sent the Messengers?",
        answers: [
          {
            answer: "Tawheed – to teach the people to worship Allah Alone",
            isCorrect: true,
          },
          {
            answer: "To perfect the character of the people",
            explanation:
              "This is one of the reasons but not the fundamental one.",
            isCorrect: false,
          },
          {
            answer: "To teach the people to follow the 5 pillars of Islam",
            explanation: "This is not the fundamental reason",
            isCorrect: false,
          },
          {
            answer:
              "To give righteous glad tidings of Paradise and to give the evil doers the news of the Hell Fire.",
            explanation:
              "Yes this is one of the reasons but not the fundamental reason.",
            isCorrect: false,
          },
        ],
      },
    ]);
  }, []);

  console.log(predefinedQuizTemplates);

  return <div></div>;
};

export default TemplateSelector;
