import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import { useAmazonContext } from "../../Contexts/AmazonContext";

// Function to fetch predefined templates from backend

const TemplateSelector = ({ onSelect }) => {
  let { predefinedTemplates, setPredefinedTemplates } = useAmazonContext();

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await api.get("/fetchTemplates");
        setPredefinedTemplates(response.data);
        console.log("Fetched Templates:", response.data);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemplates();
  }, []);

  console.log(predefinedTemplates);

  return <div></div>;
};

export default TemplateSelector;
