import React, { useState, useContext, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import { motion } from "framer-motion";
import "../sidebar/Sidebar.css";
import ConfettiExplosion from "react-confetti-explosion";
import LottieAnimation from "../LottieAnimation";
import ReactHtmlParser from "react-html-parser";
import { useAmazonContext } from "../../Contexts/AmazonContext";

const ExampleBox = styled.div`
  /* box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  padding: 10px;
  /* background: #252525; */
  /* margin-top: 80px; */
  font-family: "Quicksand", sans-serif;
`;

const ExampleQuestion = styled.div`
  /* font-size: 17px; */
  font-size: 2.5vh;
  font-weight: bold;
  text-align: center;
  color: #a678de;
  padding: 20px;
`;

const ExampleAnswer = styled(motion.div)`
  font-size: 2.5vh;
  font-weight: bold;
  text-align: center;
  font-family: Quicksand;
  /* border-radius: 15px; */
  /* padding: 10px; */
  color: #252525;
  display: flex;
  background: ${(props) =>
    props.answerClicked == props.answer ? props.answerColor : "#D2CFE6"};
  /* margin: 20px; */
  transition: transform 300ms;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const AnswerExplanation = styled.div`
  /* font-size: 2vh; */
  display: ${(props) =>
    props.answerClicked == props.answer ? "flex" : "none"};
  align-items: center;
  justify-content: center;
`;

const QuizTemplatePreview = ({ template }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [key, setKey] = useState(0);
  const [initialHover, setInitialHover] = useState(true);
  const { isDarkMode, courseTitleUserIsOn, entryStore } = useAmazonContext();

  useEffect(() => {
    let interval;
    if (isHovered) {
      if (initialHover) {
        setKey((prevKey) => prevKey + 1); // Trigger the first animation immediately
        setInitialHover(false); // Set initialHover to false after the first hover
      } else {
        interval = setInterval(() => {
          setKey((prevKey) => prevKey + 1); // Update key to restart animations
        }, 5000); // 5000ms = 5 seconds
      }
    } else {
      setInitialHover(true); // Reset initialHover when not hovered
    }
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [isHovered, initialHover]);

  const removeAutoplay = (url) => {
    const urlObj = new URL(url);
    urlObj.searchParams.delete("autoplay");
    return urlObj.toString();
  };

  return (
    <div
      className="template-preview-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ExampleBox className="LessonExampleBox">
        <ExampleQuestion>{template && template.question}</ExampleQuestion>
        {template.answers.map((answerItem) => {
          return (
            <>
              <ExampleAnswer
                className="LessonExampleAnswerBox"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.8 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                answer={answerItem.answer}
              >
                <div className="LessonExampleAnswer">{answerItem.answer}</div>
              </ExampleAnswer>
            </>
          );
        })}
        {/* Render the "Next Lesson" button if answered correctly */}{" "}
      </ExampleBox>
    </div>
  );
};

export default QuizTemplatePreview;
