import React, { useState, useCallback, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "./QuizBuilder.css";
import {
  FaTrashAlt,
  FaCopy,
  FaGripLines,
  FaCheck,
  FaTimes,
  FaCode,
} from "react-icons/fa";

const modules = {
  toolbar: [
    [{ size: [] }], // Quill will populate this with default size options
    [{ color: [] }, { background: [] }], // Quill will populate these with color pickers
    [{ align: [] }], // Quill will populate this with alignment options
    ["bold", "italic", "underline", "strike"], // Added underline and strike

    ["clean"], // Clean formatting
  ],
  clipboard: {
    matchVisual: false,
    matchers: [
      ["p", customPasteHandler],
      ["span", customPasteHandler],
      ["strong", customPasteHandler],
      ["em", customPasteHandler],
      ["u", customPasteHandler],
    ],
  },
};

const answerModules = {
  toolbar: [],
  clipboard: {
    matchVisual: false,
    matchers: [
      ["p", customPasteHandler],
      ["span", customPasteHandler],
      ["strong", customPasteHandler],
      ["em", customPasteHandler],
      ["u", customPasteHandler],
    ],
  },
};

function customPasteHandler(node, delta) {
  let plainText = "";
  delta.ops.forEach((op) => {
    if (op.insert && typeof op.insert === "string") {
      plainText += op.insert;
    }
  });

  // Remove all HTML tags and attributes
  plainText = plainText.replace(/<[^>]*>/g, "");

  // Create a new delta with only plain text
  return {
    ops: [{ insert: plainText }],
  };
}

const QuizTemplateEditor = ({
  template,
  onDelete,
  onSave,
  onDuplicate,
  dragHandleProps,
  sectionNumber,
}) => {
  const [editTemplate, setEditTemplate] = useState(template);
  const [isJsonMode, setIsJsonMode] = useState(false);
  const [jsonError, setJsonError] = useState(null);

  const handleQuestionChange = useCallback((content) => {
    setEditTemplate((prevState) => ({
      ...prevState,
      question: content,
    }));
  }, []);

  const handleAnswerChange = useCallback((index, field, value) => {
    setEditTemplate((prevState) => {
      const newAnswers = [...prevState.answers];
      newAnswers[index] = { ...newAnswers[index], [field]: value };
      return { ...prevState, answers: newAnswers };
    });
  }, []);

  const handleCorrectAnswerChange = useCallback((index) => {
    setEditTemplate((prevState) => {
      const newAnswers = prevState.answers.map((answer, i) => ({
        ...answer,
        isCorrect: i === index,
      }));
      return { ...prevState, answers: newAnswers };
    });
  }, []);

  const handleAddAnswer = useCallback(() => {
    setEditTemplate((prevState) => ({
      ...prevState,
      answers: [
        ...prevState.answers,
        { answer: "", explanation: "", isCorrect: false },
      ],
    }));
  }, []);

  const handleRemoveAnswer = useCallback((index) => {
    setEditTemplate((prevState) => ({
      ...prevState,
      answers: prevState.answers.filter((_, i) => i !== index),
    }));
  }, []);

  const handleJsonChange = useCallback((event) => {
    try {
      const newTemplate = JSON.parse(event.target.value);
      setEditTemplate(newTemplate);
      setJsonError(null);
    } catch (error) {
      setJsonError("Invalid JSON");
    }
  }, []);

  const toggleJsonMode = useCallback(() => {
    setIsJsonMode((prev) => !prev);
  }, []);

  useEffect(() => {
    onSave(editTemplate);
  }, [editTemplate]);

  const getJsonWithSectionNumber = () => {
    return JSON.stringify({ ...editTemplate, sectionNumber }, null, 2);
  };

  return (
    <div className="quiz-template-editor">
      <button onClick={toggleJsonMode} className="toggle-json-mode">
        <FaCode /> {isJsonMode ? "Visual Editor" : "JSON Editor"}
      </button>

      {isJsonMode ? (
        <div className="json-editor">
          <textarea
            value={getJsonWithSectionNumber()}
            onChange={handleJsonChange}
            rows={20}
          />
          {jsonError && <p className="error">{jsonError}</p>}
        </div>
      ) : (
        <>
          <div className="reactquill-input-group">
            <h5>Question</h5>
            <ReactQuill
              theme="bubble"
              value={editTemplate.question}
              onChange={handleQuestionChange}
              modules={modules}
              placeholder="Enter question"
              className="quiz-question-input custom-quill-editor"
            />
          </div>
          {editTemplate.answers.map((answer, index) => (
            <div key={index} className="quiz-answer-input">
              <div className="input-group">
                <h5>Answer Option</h5>
                <ReactQuill
                  theme="bubble"
                  value={answer.answer}
                  onChange={(content) =>
                    handleAnswerChange(index, "answer", content)
                  }
                  modules={answerModules}
                  placeholder={`Answer ${index + 1}`}
                  className="custom-quill-editor"
                />
              </div>
              <div className="input-group">
                <h5>Answer Explanation</h5>
                <ReactQuill
                  className="custom-quill-editor"
                  theme="bubble"
                  value={answer.explanation}
                  onChange={(content) =>
                    handleAnswerChange(index, "explanation", content)
                  }
                  modules={answerModules}
                  placeholder="Explanation (for incorrect answers)"
                />
              </div>
              <div className="lessonSelectorButtonsContainer">
                <button onClick={() => handleRemoveAnswer(index)}>
                  Remove
                </button>
                <button
                  className={`correct-answer-button ${
                    answer.isCorrect ? "correct" : ""
                  }`}
                  onClick={() => handleCorrectAnswerChange(index)}
                >
                  {answer.isCorrect ? <FaCheck /> : <FaTimes />}
                  {answer.isCorrect ? "Correct Answer" : "Mark as Correct"}
                </button>
              </div>
            </div>
          ))}
          <button onClick={handleAddAnswer}>Add Answer</button>
        </>
      )}

      <div className="quiz-template-editor-buttons">
        <button className="quiz-template-editor-button" onClick={onDelete}>
          <FaTrashAlt />
        </button>
        <button
          className="quiz-template-editor-button"
          onClick={() => onDuplicate(editTemplate)}
        >
          <FaCopy />
        </button>
      </div>
      <div className="quiz-template-editor-handle" {...dragHandleProps}>
        <FaGripLines />
      </div>
    </div>
  );
};

export default QuizTemplateEditor;
