import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import TopicHeaderSelector from "./TopicHeaderSelector";

const ChapterSelector = ({
  selectedCourse,
  selectedChapter,
  setSelectedChapter,
  setNewChapter,
  setIsExistingChapter, // Accept the prop
  setSelectedTopicHeader,
  setNewTopicHeader,
  topicHeaderDetails,
  setTopicHeaderDetails,
  topicNumber,
  setTopicNumber,
  incrementTopicNumber,
  setLesson,
  resetLessonFields,
  chapters,
  topicHeaders,
  startNewLesson,
  chapterType,
  setChapterType,
  headerType,
  setHeaderType,
  specificChapter,
  setSpecificChapter,
  quizzes,
  setQuizzes,
}) => {
  // New Chapter & New Topic Header
  useEffect(() => {
    if (chapterType === "new" && headerType === "new") {
      autoPopulateNewTopicHeaderValues();
    }
  }, [chapterType, headerType]);

  const autoPopulateNewTopicHeaderValues = () => {
    const lastTopicHeaderNumber = chapters.length
      ? Math.max(
          ...chapters.map((chapter) => Number(chapter.topicHeaderNumber))
        )
      : 0;
    const nextTopicHeaderNumber = lastTopicHeaderNumber + 1;
    const nextChapterNumber = `${nextTopicHeaderNumber}.1`;
    const firstLessonNumber = `${nextChapterNumber}.1`;
    setTopicNumber(nextChapterNumber);
    setTopicHeaderDetails({
      topicHeaderNumber: nextTopicHeaderNumber.toString(),
      topicHeader: "",
    });

    setLesson((prev) => ({
      ...prev,
      sectionNumber: firstLessonNumber,
      topicHeaderNumber: nextTopicHeaderNumber.toString(),
      topicHeader: "",
      topicNumber: nextChapterNumber,
      topicTitle: "",
    }));
  };

  // New Chapter & New Topic Header & New Lesson, use state runs when chapter and topicHeaders data is fetched via new lesson
  useEffect(() => {
    if (chapterType === "new" && headerType === "new") {
      autoPopulateNewTopicHeaderValueswithNewLesson();
    }
  }, [chapters, topicHeaders]);

  const autoPopulateNewTopicHeaderValueswithNewLesson = () => {
    const lastTopicHeaderNumber = chapters.length
      ? Math.max(
          ...chapters.map((chapter) => Number(chapter.topicHeaderNumber))
        )
      : 0;
    const nextTopicHeaderNumber = lastTopicHeaderNumber + 1;
    const nextChapterNumber = `${nextTopicHeaderNumber}.1`;
    const firstLessonNumber = `${nextChapterNumber}.1`;

    setTopicHeaderDetails({
      topicHeaderNumber: nextTopicHeaderNumber.toString(),
      topicHeader: "",
    });

    setLesson((prev) => ({
      ...prev,
      sectionNumber: firstLessonNumber,
      sectionTitle: "",
      sectionImage: "",
      topicHeaderNumber: nextTopicHeaderNumber.toString(),
      topicHeader: "",
      topicNumber: nextChapterNumber,
      topicTitle: "",
      lessonAnimation: [],
    }));

    setQuizzes([]);
  };

  //New Chapter & Existing Topic Header (This is handled in the TopicHeaderSelector component)
  const handleHeaderTypeChange = (event) => {
    setHeaderType(event.target.value);
    if (event.target.value === "existing") {
      updateNextTopicNumberAndLessonNumber();
    }
  };

  const updateNextTopicNumberAndLessonNumber = (
    selectedHeader = topicHeaderDetails
  ) => {
    const filteredChapters = chapters.filter(
      (chapter) =>
        chapter.topicHeader === selectedHeader.topicHeader &&
        chapter.topicHeaderNumber === selectedHeader.topicHeaderNumber
    );
    if (filteredChapters.length > 0) {
      const lastTopicNumber =
        filteredChapters[filteredChapters.length - 1].topicNumber;
      const nextTopicNumber = incrementTopicNumber(lastTopicNumber);
      console.log(`Last topic number: ${lastTopicNumber}`);
      console.log(`Next topic number: ${nextTopicNumber}`);
      setTopicNumber(nextTopicNumber);
      const firstLessonNumber = `${nextTopicNumber}.1`;
      console.log(`First lesson number of new chapter: ${firstLessonNumber}`);
      setLesson((prev) => ({
        ...prev,
        topicNumber: nextTopicNumber,
        sectionNumber: firstLessonNumber,
      }));
    }
  };

  // New Chapter & existing topic header and new lesson.
  useEffect(() => {
    if (chapterType === "new" && headerType === "existing") {
      const filteredChapters = chapters.filter(
        (chapter) =>
          chapter.topicHeader === topicHeaderDetails.topicHeader &&
          chapter.topicHeaderNumber === topicHeaderDetails.topicHeaderNumber
      );
      if (filteredChapters.length > 0) {
        const lastTopicNumber =
          filteredChapters[filteredChapters.length - 1].topicNumber;
        const nextTopicNumber = incrementTopicNumber(lastTopicNumber);
        console.log(`Last topic number: ${lastTopicNumber}`);
        console.log(`Next topic number: ${nextTopicNumber}`);
        setTopicNumber(nextTopicNumber);
        const firstLessonNumber = `${nextTopicNumber}.1`;
        console.log(`First lesson number of new chapter: ${firstLessonNumber}`);
        setLesson((prev) => ({
          ...prev,

          sectionTitle: "",
          sectionImage: "",
          topicTitle: "",
          lessonAnimation: [],
          topicNumber: nextTopicNumber,
          sectionNumber: firstLessonNumber,
        }));

        setQuizzes([]);
      }
    }
  }, [chapters, topicHeaders]);

  //Existing Chapter
  const handleChapterSelect = (event) => {
    const selectedChapter = chapters.find(
      (chapter) => chapter.topicNumber === event.target.value
    );
    setSelectedChapter(selectedChapter);
    setTopicHeaderDetails({
      topicHeaderNumber: selectedChapter.topicHeaderNumber,
      topicHeader: selectedChapter.topicHeader,
    });
    setTopicNumber(selectedChapter.topicNumber);
    const lastLessonNumber =
      selectedChapter.sections.length > 0
        ? selectedChapter.sections[selectedChapter.sections.length - 1]
            .sectionNumber
        : "0.0.0";
    const nextLessonNumber = incrementLessonNumber(lastLessonNumber);
    setLesson((prev) => ({
      ...prev,
      sectionNumber: nextLessonNumber,
      topicHeaderNumber: selectedChapter.topicHeaderNumber,
      topicHeader: selectedChapter.topicHeader,
      topicNumber: selectedChapter.topicNumber,
      topicTitle: selectedChapter.topicTitle,
    }));
    setSpecificChapter(selectedChapter.topicNumber); // Update specificChapter state
    console.log(
      `Selected chapter topic number: ${selectedChapter.topicNumber}`
    );
  };

  const incrementLessonNumber = (lastLessonNumber) => {
    const parts = lastLessonNumber.split(".").map(Number);
    parts[2] += 1;
    return parts.join(".");
  };

  //Existing Chapter and new lesson, runs when new button fetches new chapters and topicHeaders
  useEffect(() => {
    if (chapterType === "existing" && selectedChapter) {
      const selectedChapter = chapters.find(
        (chapter) => chapter.topicNumber === topicNumber //querying the database again with current chapter number to get latest data
      );
      setSelectedChapter(selectedChapter);
      setTopicHeaderDetails({
        topicHeaderNumber: selectedChapter.topicHeaderNumber,
        topicHeader: selectedChapter.topicHeader,
      });
      setTopicNumber(selectedChapter.topicNumber);
      const lastLessonNumber =
        selectedChapter.sections.length > 0
          ? selectedChapter.sections[selectedChapter.sections.length - 1]
              .sectionNumber
          : "0.0.0";
      const nextLessonNumber = incrementLessonNumber(lastLessonNumber);
      setLesson((prev) => ({
        ...prev,
        sectionNumber: nextLessonNumber,
        sectionTitle: "",
        sectionImage: "",
        topicHeaderNumber: selectedChapter.topicHeaderNumber,
        topicHeader: selectedChapter.topicHeader,
        topicNumber: selectedChapter.topicNumber,
        topicTitle: selectedChapter.topicTitle,
        lessonAnimation: [],
      }));

      setQuizzes([]);
      console.log(
        `Selected chapter topic number: ${selectedChapter.topicNumber}`
      );
    }
  }, [chapters, topicHeaders]);

  //  Switching between existing chapter and new chapter
  const handleChapterTypeChange = (event) => {
    setChapterType(event.target.value);
    resetLessonFields(); // Reset lesson fields when switching chapter type
    if (event.target.value === "new") {
      setNewChapter(true); // Set newChapter to true when "New Chapter" is selected
      setIsExistingChapter(false); // Ensure isExistingChapter is false
    } else {
      setNewChapter(null); // Reset newChapter if not creating a new chapter
      setIsExistingChapter(true); // Set isExistingChapter to true
    }
  };

  return (
    <div>
      <select onChange={handleChapterTypeChange} value={chapterType}>
        <option value="">Select an option</option>
        <option value="existing">Existing Chapter</option>
        <option value="new">New Chapter</option>
      </select>

      {chapterType === "existing" && (
        <div>
          <select onChange={handleChapterSelect} value={specificChapter}>
            <option value="">Select a chapter</option>
            {chapters.map((chapter) => (
              <option key={chapter.topicNumber} value={chapter.topicNumber}>
                {chapter.topicTitle}
              </option>
            ))}
          </select>
        </div>
      )}

      {chapterType === "new" && (
        <div>
          <select onChange={handleHeaderTypeChange} value={headerType}>
            <option value="">Select an option</option>
            <option value="existing">Existing Topic Header</option>
            <option value="new">New Topic Header</option>
          </select>

          {headerType === "existing" && (
            <TopicHeaderSelector
              selectedCourse={selectedCourse}
              setSelectedTopicHeader={setSelectedTopicHeader}
              setNewTopicHeader={setNewTopicHeader}
              headerType={headerType}
              setTopicHeaderDetails={setTopicHeaderDetails}
              updateNextTopicNumberAndLessonNumber={
                updateNextTopicNumberAndLessonNumber
              }
              topicHeaders={topicHeaders}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChapterSelector;
