import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../api";

const TopicHeaderSelector = ({
  selectedCourse,
  setSelectedTopicHeader,
  setNewTopicHeader,
  headerType,
  setTopicHeaderDetails,
  updateNextTopicNumberAndLessonNumber, // Add this prop
  topicHeaders,
}) => {
  //New Chapter & Existing Topic Header
  const handleTopicHeaderSelect = (event) => {
    const selectedHeader = topicHeaders.find(
      (header) => header.topicHeader === event.target.value
    );
    setSelectedTopicHeader(selectedHeader);
    setTopicHeaderDetails(selectedHeader); // Set the details
    updateNextTopicNumberAndLessonNumber(selectedHeader); // Call the function to update the next topic number and first lesson number
  };

  const handleNewTopicHeader = () => {
    const newHeader = {
      topicHeader: "",
      topicHeaderNumber: "",
    };
    setNewTopicHeader(newHeader);
  };

  return (
    <div>
      {headerType === "existing" && (
        <div>
          <select onChange={handleTopicHeaderSelect}>
            <option value="">Select a topic header</option>
            {topicHeaders.map((header) => (
              <option key={header.topicHeader} value={header.topicHeader}>
                {header.topicHeader}
              </option>
            ))}
          </select>
        </div>
      )}

      {headerType === "new" && (
        <div>
          <button onClick={handleNewTopicHeader}>
            Create New Topic Header
          </button>
        </div>
      )}
    </div>
  );
};

export default TopicHeaderSelector;
